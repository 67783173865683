import axios from '../../../utils/axios';
import { all, call, fork, put, retry, takeLatest, select } from 'redux-saga/effects';
import { sagaErrorHandler } from 'shared/helperMethods/sagaErrorHandler';
import { makeSelectAuthToken } from '../../../shared/helperMethods/Selectors';
import { SetNotification } from 'shared/helperMethods/setNotification';
import { getAllCategoriesSucess, setLoader } from './actions';
import { GET_ALL_CATEGORIES, ADD_QUESTION, EDIT_QUESTION, DELETE_QUESTION, CHANGE_FILE } from './constants';
import { getAllSkills } from '../skills/actions';

function* addQuestionRequest({ payload }) {
    try {
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
        const response = yield axios.post(`/questions`, payload.formData, headers);
        payload.hanldeModalClose();
        yield put(
            getAllSkills({
                id: payload.id,
                limit: payload.limit,
                page: payload.page,
                search: payload.search
            })
        );

        yield SetNotification('success', response.data.message);
    } catch (error) {
        console.log('response.data.message', error);
    }
}

export function* watchAddQuestion() {
    yield takeLatest(ADD_QUESTION, addQuestionRequest);
}

function* editQuestionRequest({ payload }) {
    yield put(setLoader(true));
    try {
        let data = {
            ...payload.data
        };
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
        const response = yield axios.put(`/questions/${payload.questionId}/details`, data, headers);
        yield put(
            getAllSkills({
                id: payload.id,
                limit: payload.limit,
                page: payload.page,
                search: payload.search
            })
        );
        yield put(setLoader(false));
       
        yield SetNotification('success', 'Option Updated Successfully');
    } catch (error) {
        yield put(setLoader(false));
        yield sagaErrorHandler(error.response.data);
    }
}

export function* watchEditQuestion() {
    yield takeLatest(EDIT_QUESTION, editQuestionRequest);
}

function* deleteQuestionRequest({ payload }) {
    try {
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
        const response = yield axios.delete(`/questions/${payload.id}`, headers);
        yield put(
            getAllSkills({
                id: payload.letter,
                limit: payload.limit,
                page: payload.page,
                search: payload.search
            })
        );
        yield SetNotification('success', response.data.message);
        payload.handleClose();
    } catch (error) {
        yield sagaErrorHandler(error.response.data);
    }
}

export function* watchDeleteQuestion() {
    yield takeLatest(DELETE_QUESTION, deleteQuestionRequest);
}
function* getAllCategoriesRequest({}) {
    try {
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
        const response = yield axios.get(`/categories`, headers);
        let categoriesList = response.data.data.map((category) => {
            return {
                value: category.id,
                label: category.label,
                name: category.name
            };
        });
        yield put(getAllCategoriesSucess(categoriesList));
    } catch (error) {
        yield sagaErrorHandler(error.response.data);
    }
}

export function* watchGetAllCategories() {
    yield takeLatest(GET_ALL_CATEGORIES, getAllCategoriesRequest);
}

function* changeFileRequest({ payload }) {
    yield put(setLoader(true));
    try {
        const headers = { headers: { 'auth-token': yield select(makeSelectAuthToken()) } };
        yield axios.post(`/questions/${payload.optionId}/upload`, payload.formData, headers);
        yield put(
            getAllSkills({
                id: payload.id,
                limit: payload.limit,
                page: payload.page,
                search: payload.search
            })
        );
        yield put(setLoader(false));
       
        yield SetNotification('success', 'Option Updated Successfully');
    } catch (error) {
        yield put(setLoader(false));
        yield sagaErrorHandler(error.response.data);
    }
}

export function* watchChangeFile() {
    yield takeLatest(CHANGE_FILE, changeFileRequest);
}

export default function* questionsSaga() {
    yield all([fork(watchGetAllCategories), fork(watchAddQuestion), fork(watchEditQuestion), fork(watchDeleteQuestion), fork(watchChangeFile)]);
}
