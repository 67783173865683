import { GET_ALL_CATEGORIES, GET_ALL_CATEGORIES_SUCCESS, ADD_QUESTION, EDIT_QUESTION, DELETE_QUESTION, SET_LOADER, CHANGE_FILE } from './constants';

export const addQuestion = (data) => {
    return {
        type: ADD_QUESTION,
        payload: data
    };
};
export const editQuestion = (data) => {
    return {
        type: EDIT_QUESTION,
        payload: data
    };
};
export const deleteQuestion = (data) => {
    return {
        type: DELETE_QUESTION,
        payload: data
    };
};
export const getAllCategories = () => {
    return {
        type: GET_ALL_CATEGORIES
    };
};

export const getAllCategoriesSucess = (data) => {
    return {
        type: GET_ALL_CATEGORIES_SUCCESS,
        payload: data
    };
};

export const setLoader = (data) => {
    return {
        type: SET_LOADER,
        payload: data
    };
};
export const changeFile = (data) => {
    return {
        type: CHANGE_FILE,
        payload: data
    };
};