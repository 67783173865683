import produce from 'immer';
import { GET_ALL_CATEGORIES_SUCCESS, SET_LOADER} from './constants';

const INITIAL_STATE = {
    loader: false,
    categoriesList: [],
   
};

const questionsReducer = produce((draft, action) => {
    switch (action.type) {
        case GET_ALL_CATEGORIES_SUCCESS:
            draft.categoriesList = action.payload;
            break;
        case SET_LOADER:
            draft.loader = action.payload;
            break;

        default:
    }
}, INITIAL_STATE);

export default questionsReducer;
